// library
import React from 'react';

// components
import Button from '@components/atoms/button/button';
import Input from '@components/atoms/input/input';
import styles from './otp-form-field.module.scss';

// temp component, expect do in form-field, but form-field component have other ticket updating.
const OtpFormField = ({ ...props }) => {
    const { label, appendLabel, name, value, onClick, onChange, disabled, buttonText, errMsg, verifyAttemptMsg, placeholder, layout, isMandatory } = props;
    return (
        <>
            <div className={`${styles.otpField} standard-form-field otp-form-field ${styles?.[layout] || ''}`}>
                <label className={`${styles.otpFieldLabel} standard-label ${isMandatory ? 'standard-mandatory' : ''}`}>
                    {label} {appendLabel}
                </label>

                <div className={`${styles.otpFieldWrapper} standard-form-field-wrapper`}>
                    <Input className={`standard-input ${styles.otpInput}`} name={name} value={value} onChange={onChange} placeholder={placeholder} />
                    <div className={`${styles.otpButtonContainer}`}>
                        <Button
                            id={'otp-btn'}
                            className={`standard-button standard-submit-button btn-primary-color ${styles.otpButton}`}
                            disabled={disabled}
                            onClick={onClick}
                        >
                            <span>{buttonText}</span>
                        </Button>
                    </div>
                </div>

                {errMsg && <div className={`${styles.errorValidation} standard-error-validation ${styles?.[layout] || ''}`}>{errMsg}</div>}
                {verifyAttemptMsg && <div className={`${styles.errorValidation} standard-error-validation ${styles?.[layout] || ''}`}>{verifyAttemptMsg}</div>}
            </div>
        </>
    );
};

export default OtpFormField;
