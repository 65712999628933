import { layoutConstants } from '../constant';

const initialState = {
  designs: null
};

export function layout(state = initialState, action) {
  switch (action.type) {
    case layoutConstants.SET_LAYOUT:
      return {
        ...state,
        designs: action.payload
      };
    default:
      return state;
  }
}