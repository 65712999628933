import React from 'react';
const Loading = (props) => {
  //   useEffect(() => {
  //     const logo = document.querySelectorAll('#logo path');

  //     for (let i = 0; i < logo.length; i++) {
  //       console.log(`Letter ${i} is ${logo[i].getTotalLength()}`);
  //     }
  //   }, []);
  return (
    <div className='loading-container'>
      <div className='loading'>
        <svg id='logo' width='485' height='111' viewBox='0 0 485 111' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M47.7734 89.0703L77.0234 4.625H91.7891L53.8203 107H41.8672L3.96875 4.625H18.6641L47.7734 89.0703Z'
            stroke='black'
            strokeWidth='5'
          />
          <path d='M120.055 107H106.555V4.625H120.055V107Z' stroke='black' strokeWidth='5' />
          <path
            d='M220.391 93.5703C216.922 98.5391 212.07 102.266 205.836 104.75C199.648 107.188 192.43 108.406 184.18 108.406C175.836 108.406 168.43 106.461 161.961 102.57C155.492 98.6328 150.477 93.0547 146.914 85.8359C143.398 78.6172 141.594 70.25 141.5 60.7344V51.8047C141.5 36.3828 145.086 24.4297 152.258 15.9453C159.477 7.46094 169.602 3.21875 182.633 3.21875C193.32 3.21875 201.922 5.96094 208.438 11.4453C214.953 16.8828 218.938 24.6172 220.391 34.6484H206.891C204.359 21.1016 196.297 14.3281 182.703 14.3281C173.656 14.3281 166.789 17.5156 162.102 23.8906C157.461 30.2188 155.117 39.4062 155.07 51.4531V59.8203C155.07 71.3047 157.695 80.4453 162.945 87.2422C168.195 93.9922 175.297 97.3672 184.25 97.3672C189.312 97.3672 193.742 96.8047 197.539 95.6797C201.336 94.5547 204.477 92.6562 206.961 89.9844V66.9922H183.266V56.0234H220.391V93.5703Z'
            stroke='black'
            strokeWidth='5'
          />
          <path
            d='M321.711 59.1172C321.711 69.1484 320.023 77.9141 316.648 85.4141C313.273 92.8672 308.492 98.5625 302.305 102.5C296.117 106.438 288.898 108.406 280.648 108.406C272.586 108.406 265.438 106.438 259.203 102.5C252.969 98.5156 248.117 92.8672 244.648 85.5547C241.227 78.1953 239.469 69.6875 239.375 60.0312V52.6484C239.375 42.8047 241.086 34.1094 244.508 26.5625C247.93 19.0156 252.758 13.25 258.992 9.26562C265.273 5.23438 272.445 3.21875 280.508 3.21875C288.711 3.21875 295.93 5.21094 302.164 9.19531C308.445 13.1328 313.273 18.875 316.648 26.4219C320.023 33.9219 321.711 42.6641 321.711 52.6484V59.1172ZM308.281 52.5078C308.281 40.3672 305.844 31.0625 300.969 24.5938C296.094 18.0781 289.273 14.8203 280.508 14.8203C271.977 14.8203 265.25 18.0781 260.328 24.5938C255.453 31.0625 252.945 40.0625 252.805 51.5938V59.1172C252.805 70.8828 255.266 80.1406 260.188 86.8906C265.156 93.5938 271.977 96.9453 280.648 96.9453C289.367 96.9453 296.117 93.7812 300.898 87.4531C305.68 81.0781 308.141 71.9609 308.281 60.1016V52.5078Z'
            stroke='black'
            strokeWidth='5'
          />
          <path
            d='M388.438 62C385.719 65.2344 382.461 67.8359 378.664 69.8047C374.914 71.7734 370.789 72.7578 366.289 72.7578C360.383 72.7578 355.227 71.3047 350.82 68.3984C346.461 65.4922 343.086 61.4141 340.695 56.1641C338.305 50.8672 337.109 45.0312 337.109 38.6562C337.109 31.8125 338.398 25.6484 340.977 20.1641C343.602 14.6797 347.305 10.4844 352.086 7.57812C356.867 4.67188 362.445 3.21875 368.82 3.21875C378.945 3.21875 386.914 7.01562 392.727 14.6094C398.586 22.1562 401.516 32.4688 401.516 45.5469V49.3438C401.516 69.2656 397.578 83.8203 389.703 93.0078C381.828 102.148 369.945 106.836 354.055 107.07H351.523V96.1016H354.266C365 95.9141 373.25 93.125 379.016 87.7344C384.781 82.2969 387.922 73.7188 388.438 62ZM368.398 62C372.758 62 376.766 60.6641 380.422 57.9922C384.125 55.3203 386.82 52.0156 388.508 48.0781V42.875C388.508 34.3438 386.656 27.4062 382.953 22.0625C379.25 16.7188 374.562 14.0469 368.891 14.0469C363.172 14.0469 358.578 16.25 355.109 20.6562C351.641 25.0156 349.906 30.7812 349.906 37.9531C349.906 44.9375 351.57 50.7031 354.898 55.25C358.273 59.75 362.773 62 368.398 62Z'
            stroke='black'
            strokeWidth='5'
          />
          <path
            d='M469.297 62C466.578 65.2344 463.32 67.8359 459.523 69.8047C455.773 71.7734 451.648 72.7578 447.148 72.7578C441.242 72.7578 436.086 71.3047 431.68 68.3984C427.32 65.4922 423.945 61.4141 421.555 56.1641C419.164 50.8672 417.969 45.0312 417.969 38.6562C417.969 31.8125 419.258 25.6484 421.836 20.1641C424.461 14.6797 428.164 10.4844 432.945 7.57812C437.727 4.67188 443.305 3.21875 449.68 3.21875C459.805 3.21875 467.773 7.01562 473.586 14.6094C479.445 22.1562 482.375 32.4688 482.375 45.5469V49.3438C482.375 69.2656 478.438 83.8203 470.562 93.0078C462.688 102.148 450.805 106.836 434.914 107.07H432.383V96.1016H435.125C445.859 95.9141 454.109 93.125 459.875 87.7344C465.641 82.2969 468.781 73.7188 469.297 62ZM449.258 62C453.617 62 457.625 60.6641 461.281 57.9922C464.984 55.3203 467.68 52.0156 469.367 48.0781V42.875C469.367 34.3438 467.516 27.4062 463.812 22.0625C460.109 16.7188 455.422 14.0469 449.75 14.0469C444.031 14.0469 439.438 16.25 435.969 20.6562C432.5 25.0156 430.766 30.7812 430.766 37.9531C430.766 44.9375 432.43 50.7031 435.758 55.25C439.133 59.75 443.633 62 449.258 62Z'
            stroke='black'
            strokeWidth='5'
          />
        </svg>
      </div>
    </div>
  );
};

export default Loading;
