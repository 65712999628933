import { popupConstants } from '../constant';
import { POPUP_TYPE } from '@constants';

const initialState = {
    items: {
        GA: false,
        PIN_ENTRY: false,
        EDIT_VERIFY_AUTH: false,
        [POPUP_TYPE.SIX_PIN_SETUP]: false,
    },
    config: {
        [POPUP_TYPE.EDIT_VERIFY_AUTH]: {
            selectedAuthContact: null, // used for control control fields/translation for edit member info popup
        },
        [POPUP_TYPE.PIN_ENTRY]: {
            // used for control translations, GA / SIX_PIN using same layout and logic.
            isGaEnabled: false,
            isSixPinEnabled: false,
        },
    },
    submitSuccessCb: null,
    enteredPin: '',
};

export function popupReducer(state = initialState, action) {
    switch (action.type) {
        case popupConstants.TOGGLE_POPUP:
            return {
                ...state,
                items: {
                    ...state.items,
                    [action?.payload?.item]: action?.payload?.isVisible,
                },
            };

        case popupConstants.SET_SUBMIT_SUCCESS_CB:
            return {
                ...state,
                submitSuccessCb: action?.payload?.fn,
            };

        case popupConstants.SET_ENTERED_PIN:
            return {
                ...state,
                enteredPin: action?.payload?.enteredPin,
            };

        // each popup might have config required
        case popupConstants.SET_ITEM_CONFIG:
            return {
                ...state,
                config: {
                    ...state.config,
                    [action?.payload?.item]: action?.payload?.config,
                },
            };
        default:
            return state;
    }
}
