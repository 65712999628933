import { passwordConstants } from '../constant/password.constant';

export const passwordAction = {
    fetchGetPasswordConfigApi,
    fetchProcessedPasswordConfig,
};

function fetchGetPasswordConfigApi(isSkipCheckPasswordConfig) {
    return (dispatch) => {
        if (isSkipCheckPasswordConfig) {
            return { passwordConfigApiData: {} };
        } else {
            window.SPL_Register.getPasswordRules().then((res) => {
                dispatch({ type: passwordConstants.PASSWORD_CONFIG_API_DATA, payload: { passwordConfigApiData: res || {} } });
            });
        }
    };
}

/**
 * get processed config (value depends if API got value or no), each condition will have own set value.
 */
function fetchProcessedPasswordConfig() {
    return (dispatch, getState) => {
        const { password } = getState();

        if (password?.processedData?.passwordConfig) {
            return;
        }

        const passwordConfigApiData = password?.rawData?.passwordConfigApiData;

        const alphabetValidateRules = passwordConfigApiData?.uppercase ? '(?=.*[A-Z])' : '(?=.*[A-Za-z])';
        const digitAndAlphabetPattern = new RegExp(`${alphabetValidateRules}(?=.*[0-9])[a-zA-Z0-9]{1,}.+$`);

        const updatedSpecialChars = {
            ...passwordConfigApiData?.specialChars,
            format: passwordConfigApiData?.specialChars?.format || '~`!@#\\$%^&*\\(\\)_\\-+={\\[}\\]|\\\\:;"\'<,>.?/',
        };

        const passwordValidatePattern = new RegExp(
            `^${alphabetValidateRules}${
                updatedSpecialChars?.mandatory && passwordConfigApiData?.newErrorFormat ? '(?=.*[' + updatedSpecialChars?.format + '])' : ''
            }(?=.*[0-9])[a-zA-Z0-9${updatedSpecialChars?.allow && passwordConfigApiData?.newErrorFormat ? updatedSpecialChars?.format : ''}]{1,}$`
        );

        const skipAlphanumericCheckPattern = new RegExp(`[a-zA-Z0-9${updatedSpecialChars?.allow ? updatedSpecialChars?.format : ''}]$`);

        const finalPasswordValidationPattern = passwordConfigApiData?.skipAlphanumericCheck ? skipAlphanumericCheckPattern : passwordValidatePattern;
        const passwordConfig = {
            ...passwordConfigApiData,
            minLength: passwordConfigApiData?.minLength || 6,
            maxLength: passwordConfigApiData?.maxLength || 12,
            specialChars: updatedSpecialChars,
            digitAndAlphabetPattern: digitAndAlphabetPattern,
            pwPattern: finalPasswordValidationPattern,
        };

        dispatch({ type: passwordConstants.PASSWORD_CONFIG, payload: { passwordConfig: passwordConfig } });
    };
}
