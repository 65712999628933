import { constSystemFeature } from '../constant';

const initialState = {
    systemFeature: {},
};

export function systemFeatureReducer(state = initialState, action) {
    switch (action.type) {
        case constSystemFeature.LOAD_SYSTEM_FEATURE:
            return {
                ...state,
                systemFeature: action.payload,
            };
        default:
            return state;
    }
}
