import { languageConstants } from '../constant/language.constant';

export const languageAction = {
  changeLanguage
};

function changeLanguage(languageObj) {
  return dispatch => {
    const { countryLanguageKey } = languageObj;
    const lengthLanguage = countryLanguageKey.length;
    const country = countryLanguageKey.substring(lengthLanguage - 2, lengthLanguage);
    setTimeout(() => {
      if(window.SPL_Content) {
        clearInterval(this);
        window.SPL_Content.getCurrencyFromSelectedLanguage(countryLanguageKey).then(res => {
          languageObj.country = country;
          languageObj.currencyLang = res.currency;
          languageObj.src = res.src;
          languageObj.display = res.display;
          dispatch({ type: languageConstants.SET_LANGUAGE, payload: languageObj });
        });
      }
    }, 10);
  };
}
