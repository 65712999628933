import { portalConstants } from '../constant';

const initialState = {
    settings: null,
    isLoading: false,
    isSubmitting: false,
    siteKey: '',
};

export function portal(state = initialState, action) {
    switch (action.type) {
        case portalConstants.SET_PORTAL_SETTINGS:
            return {
                ...state,
                settings: action.payload,
            };
        case portalConstants.PORTAL_LOADING:
            return {
                ...state,
                isLoading: action.payload.isLoading,
            };
        case portalConstants.SET_IS_SUBMITTING:
            return {
                ...state,
                isSubmitting: action.payload.isSubmitting,
            };
        case portalConstants.CLOUDFLARE_TURNSTILE:
            return {
                ...state,
                siteKey: action.payload.siteKey,
            };
        default:
            return state;
    }
}
