import React from 'react';
import Loading_01 from '../Loading/Loading_01/Loading_01';
import Loading_02 from '../Loading/Loading_02/Loading_02';
import Loading_03 from '../Loading/Loading_03/Loading_03';
import Loading_04 from '../Loading/Loading_04/Loading_04';
import Loading_08 from '../Loading/Loading_08/Loading_08';

const Loading = (props) => {
  // useEffect(() => {
  //   const logo = document.querySelectorAll('#logo path');

  //   for (let i = 0; i < logo.length; i++) {
  //     console.log(`Letter ${i} is ${logo[i].getTotalLength()}`);
  //   }
  // }, []);
  const getLoadLayout = () => {
    let layout = window.layoutGroup;
    if (['L03'].includes(layout)) {
      return 'Loading_03';
    } else if (['L02'].includes(layout)) {
      return 'Loading_02';
    } else if (['L04'].includes(layout)) {
      return 'Loading_04';
    }
    else if (['L08'].includes(layout)) {
      return 'Loading_08';
    }
    else {
      // default or no special requirement go to else
      return 'Loading_08';
    }
  };
  return (
    <div className='loading-container'>
      {getLoadLayout() === 'Loading_01' && <Loading_01 />}
      {getLoadLayout() === 'Loading_02' && <Loading_02 />}
      {getLoadLayout() === 'Loading_03' && <Loading_03 />}
      {getLoadLayout() === 'Loading_04' && <Loading_04 />}
      {getLoadLayout() === 'Loading_08' && <Loading_08 />}
    </div>
  );
};

export default Loading;
