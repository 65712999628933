// data
import CryptoJS from 'crypto-js';
import moment from 'moment';
import { userAction } from 'root/redux/action';
// NOTE: This file is same as commmon-util
// MAIN PURPOSE: enhancement project performance, without import all the unused function
// The way is to export function 1 by 1 for this file
// All function name start from gu_ (general util) to indicate the function come from here

export function gu_togglePopup(vm, component, customPopUpContent) {
    let popupCollection = vm.state.popupCollection ? vm.state.popupCollection : {};
    popupCollection[component] = !popupCollection[component];
    vm.setState({
        popupCollection,
        customPopUpContent,
    });
}

export function gu_contactOnClick(url) {
    window.open(url, '', 'width=500,height=700,toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=no');
}

export function gu_handleUploadReceiptButtonOnClick() {
    let hiddenInput = document.getElementById('hiddenFileInput');
    if (hiddenInput) {
        hiddenInput.click();
    } else {
        console.log('hiddenFileInput is not found');
    }
}

// Params
// vm (Required) -> this
// settingName (Required) -> the name of setting is getting
// defaultValue -> return specific value if setting not found
// notFeatures -> if the setting not inside features set true
// notViewType -> if the setting not contain 'web' and 'mobile' set true
// isCurrencyLang -> if the setting is define by CurrencyLanguage or countryLanguageKey
// isAffFeatures -> get settings from affFeatures instead of features
export function gu_getFeaturesFromPortalSettings(vm, isAffFeatures = false) {
    const { portal } = vm.props;

    if (portal && portal.settings) {
        if (isAffFeatures) {
            if (portal.settings.affFeatures) {
                return portal.settings.affFeatures;
            }
        } else {
            if (portal.settings.features) {
                return portal.settings.features;
            }
        }
    }
    return {};
}

export function gu_getByViewType(obj, vm) {
    if (!vm) {
        alert('vm is not valid/passed into function getByViewType()');
        return;
    }

    const { screen } = vm.props;
    let viewType = 'web'; // default if not found
    if (screen && screen.viewType) {
        viewType = screen.viewType;
    }
    return obj && (obj[viewType] || obj[viewType] === '' || typeof obj[viewType] === 'boolean') ? obj[viewType] : obj;
}

export function gu_getContentByCurrencyLanguage(vm, content, countryLanguageKeySettings = {}) {
    let countryLanguageKey = null;
    let country = null;
    let currency = null;
    let languageKey = null;

    if (vm && vm.props && content) {
        const { language } = vm.props;
        let user = null;

        // Step 1: Get selected currency and language
        // Step 1.0: Get language key
        if (vm.props.oriProps && vm.props.oriProps.language) {
            languageKey = vm.props.oriProps.language.key;
            country = vm.props.oriProps.language.country;
        } else if (vm.props.language) {
            languageKey = vm.props.language.key;
            country = vm.props.language.country;
        }

        // used for the case if some content got manual get by language.toLowerCase()
        if (content.convertLangToLowerCase) {
            languageKey = languageKey.toLowerCase();
        }

        // Step 1.1: Check if login, get account currency
        if (vm.props.oriProps) {
            if (vm.props.oriProps.user && vm.props.oriProps.user.isLogin) {
                user = vm.props.oriProps.user;
            }
        } else if (vm.props.user && vm.props.user.isLogin) {
            user = vm.props.user;
        }

        const convertUSD = () => {
            if (vm.props.oriProps) {
                currency = vm.props.oriProps.language.country + vm.props.currencyLang;
            } else if (countryLanguageKeySettings && countryLanguageKeySettings.convertUSD) {
                currency = vm.props.language.country + (language && language.currencyLang);
            }
        };

        if (user && user.account) {
            currency = user.account.currency;
            if (currency === 'USD' || currency === 'EUR') {
                convertUSD();
            }
        }

        // Step 1.2: If not login, get current currencyLang based on language selected
        if (!currency) {
            if (vm.props.currencyLang || (language && language.currencyLang)) {
                currency = vm.props.currencyLang || (language && language.currencyLang);
                if (currency === 'USD' || currency === 'EUR') {
                    convertUSD();
                }
            }
        }
        if (currency && currency.currencyLang) {
            currency = currency.currencyLang;
        }

        // Step 2: Get content
        // Step 2.1: Check if content contains selected currency
        if (content[country] || typeof content[country] === 'boolean') {
            const regionContent = content[country];
            if (regionContent[languageKey] || typeof regionContent[languageKey] === 'boolean') {
                return regionContent[languageKey];
            }
            return regionContent;
        } else if (content[currency] || typeof content[currency] === 'boolean') {
            let selectedContentJson = content[currency];
            if (selectedContentJson[languageKey] || typeof selectedContentJson[languageKey] === 'boolean') {
                // Step 2.1.1: Get correct currency > languageKey
                return selectedContentJson[languageKey];
            } else if ((selectedContentJson['en'] || typeof selectedContentJson['en'] === 'boolean') && !selectedContentJson.disableDefaultTranslate) {
                // Step 2.1.2: Get default languageKey 'en'
                return selectedContentJson['en'];
            } else if (selectedContentJson.disableDefaultTranslate) {
                return null;
            } else {
                return selectedContentJson;
            }
        } else if (content['ALL'] || typeof content['ALL'] === 'boolean') {
            // Step 2.2: Check if having general translate for all language
            let selectedContentJson = content['ALL'];
            if (selectedContentJson[languageKey] || typeof selectedContentJson[languageKey] === 'boolean') {
                // Step 2.2.1: Get correct currency > languageKey
                return selectedContentJson[languageKey];
            } else if ((selectedContentJson['en'] || typeof selectedContentJson['en'] === 'boolean') && !selectedContentJson.disableDefaultTranslate) {
                // Step 2.2.2: Get default languageKey 'en'
                return selectedContentJson['en'];
            } else if (selectedContentJson.disableDefaultTranslate) {
                return null;
            } else {
                return selectedContentJson;
            }
        } else {
            // If content already filter/without currency, direct take content by language
            // **Not recommended, try to avoid
            if ((content[languageKey] || typeof content[languageKey] === 'boolean') && !content.disableLangProps) {
                return content[languageKey];
            } else if ((content['en'] || typeof content['en'] === 'boolean') && !content.disableDefaultTranslate) {
                return content['en'];
            } else if (content.disableDefaultTranslate) {
                return null;
            }

            // Step 3: Fallback control - used for countryLanguage key like en-my, ms-my
            if (vm.props.language && vm.props.language.countryLanguageKey) {
                countryLanguageKey = vm.props.language.countryLanguageKey;
            } else if (vm.props.countryLanguageKey) {
                countryLanguageKey = vm.props.countryLanguageKey;
            }
            let defaultCountryLanguageKey = window.countryLanguageKey;

            if (!countryLanguageKeySettings.noLowerCase) {
                countryLanguageKey = countryLanguageKey.toLowerCase();
                defaultCountryLanguageKey = defaultCountryLanguageKey.toLowerCase();
            }
            if (!countryLanguageKeySettings.showUnderScore) {
                countryLanguageKey = countryLanguageKey.replace('_', '-');
                defaultCountryLanguageKey = defaultCountryLanguageKey.replace('_', '-');
            }

            if (countryLanguageKey && content[countryLanguageKey]) {
                // Step 3.1: Try to get current countryLanguageKey
                return content[countryLanguageKey];
            } else if (content[defaultCountryLanguageKey]) {
                // Step 3.2: Try to get default countryLanguageKey
                return content[defaultCountryLanguageKey];
            } else {
                return content; // Return back contnet if all condition does not match
            }
        }
    } else {
        return content;
    }
}

// Params
// vm (Required) -> this
// settingName (Required) -> the name of setting is getting
// defaultValue -> return specific value if setting not found
// notFeatures -> if the setting not inside features set true
// notViewType -> if the setting not contain 'web' and 'mobile' set true
// isCurrencyLang -> if the setting is define by CurrencyLanguage or countryLanguageKey
// isAffFeatures -> get settings from affFeatures instead of features
export function gu_getSettingFromPortalSettings(params) {
    const { vm, settingName } = params;
    let settingObj = null;

    if (vm && vm.props) {
        let portal = vm.props.portal;

        // Get the settings out from portal settings
        if (params.notFeatures) {
            if (portal && portal.settings && portal.settings[settingName]) {
                settingObj = portal.settings[settingName];
            }
        } else {
            let featureObj = gu_getFeaturesFromPortalSettings(vm, params.isAffFeatures);
            if (featureObj) {
                settingObj = featureObj[settingName];
            }
            // if (params.isAffFeatures) {
            //     if (portal && portal.settings && portal.settings.affFeatures && portal.settings.affFeatures[settingName]) {
            //         settingObj = portal.settings.affFeatures[settingName];
            //     }
            // } else {
            //     if (portal && portal.settings && portal.settings.features && portal.settings.features[settingName]) {
            //         settingObj = portal.settings.features[settingName];
            //     }
            // }
        }

        if (settingObj) {
            // Check screen view type
            if (!params.notViewType) {
                settingObj = gu_getByViewType(settingObj, vm);
            }

            // Check if currency language needed
            if (params.isCurrencyLang) {
                settingObj = gu_getContentByCurrencyLanguage(vm, settingObj);
            }
        }
    } else {
        alert('Error! Calling getSettingFromPortalSettings function without vm / settingName');
    }

    if (settingObj) {
        return settingObj;
    } else if (!settingObj && params.defaultValue !== undefined) {
        return params.defaultValue;
    } else {
        return {};
    }
}

export function gu_getNestedValue(obj, key) {
    try {
        key = key.replace(/\[(\w+)\]/g, '.$1'); // split indexes.
        key = key.replace(/^\.+/, ''); // remove leading dot.
        const a = key.split('.');
        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (k in obj) {
                obj = obj[k];
            } else {
                return;
            }
        }
        return obj;
    } catch (err) {
        return undefined;
    }
}

// String handling
export function gu_replaceAll(str, search, replacement) {
    return str.replace(new RegExp(search, 'g'), replacement);
}

export function gu_getFourDResult(date, customTimeStampDate, customIntervalRoundingUnit) {
    const formattedDateTime = gu_formatNewDateToCustomFormat(date, 'YYYY_MM_DD');
    const filePath = `/draw-results/results/${formattedDateTime}/become.js`;
    const updatedFilePath = gu_appendTimeStampIntoPath(filePath, customTimeStampDate, customIntervalRoundingUnit);

    try {
        const request = new XMLHttpRequest();
        request.open('GET', updatedFilePath, false); // Third parameter is set to 'false' for synchronous
        request.send(null);
        const scriptText = request.responseText;

        const uniqueSecretKey = 'ZGpmZ2tobmVpdGhmcnRoZ252dXRuZGh0amZodm5ybmU=';
        const decryptedData = gu_decryptData(gu_replaceAll(scriptText, /\|\|/g, '//'), uniqueSecretKey);
        return decryptedData;
    } catch (err) {
        return '';
    }
}
/**
 * extract the regex, convert from string to JSON
 * @param {string} data
 * @param {string} regex
 */
export function gu_extractUnusedKeywordsForDrawResults(data, regex) {
    const jsonString = data?.match(regex)?.[0];
    try {
        const jsonData = JSON.parse(jsonString) || '';
        return jsonData;
    } catch (err) {
        return '';
    }
}

/**
 * convert the date format in order to retrieve correct data from BE
 * e.g. Tue Nov 07 2023 10:48:57 GMT+0800 (Singapore Standard Time) => '2023_11_07'
 * @param {Date} date
 */
export function gu_formatNewDateToCustomFormat(date, dateFormat) {
    const returnDate = moment(date).utcOffset('+08:00').format(dateFormat);
    return returnDate;
}

/**
 * Special function for 4D draw results (only appends timestamp for every 30 seconds)
 * @param {string} path
 * @returns
 */
export function gu_appendTimeStampIntoPath(path, customTimeStampDate, customIntervalRoundingUnit) {
    let connector = '?';
    if (path.includes('?')) {
        connector = '&';
    }
    const roundedTime = customTimeStampDate
        ? new Date(customTimeStampDate)
        : new Date(Math.floor(new Date() / customIntervalRoundingUnit || 30000) * customIntervalRoundingUnit || 30000); // Round down to the nearest 30 seconds
    const timeStamp = Math.trunc(roundedTime.getTime() / 1000); // Convert to seconds = 1000, minutes = 60000

    const updatedPath = path + connector + 'timeStamp=' + timeStamp;

    return updatedPath;
}

export function gu_decryptData(data, secretKey) {
    try {
        const decodeBase64SecretKey = CryptoJS.enc.Base64.parse(secretKey);

        const encryptString = data;
        const randomString = 'qkemtdhted567fd988213kdecrqkemtdhted567fd988213kdecrqkemtdhted567fd988213kdec';
        const preDecodeString = encryptString.replace(randomString, '');
        const decodeBase64EncryptedData = CryptoJS.enc.Base64.parse(preDecodeString);

        const decryptedData = CryptoJS.AES.decrypt(
            {
                ciphertext: decodeBase64EncryptedData,
            },
            decodeBase64SecretKey,
            {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,
            }
        ).toString(CryptoJS.enc.Utf8);
        return decryptedData;
    } catch (err) {
        console.error('decrypt data error', err);
    }
}

export function gu_getFourDPayout(language) {
    let fileUrl = `${window.location.origin}/draw-results/payout/mock/payout_${language}.json`;
    let request = new XMLHttpRequest();
    request.open('GET', fileUrl, false); // Third parameter is set to 'false' for synchronous
    request.send(null);

    if (request.status === 200) {
        let scriptText = request.responseText;
        return scriptText;
    } else {
        console.error('Error fetching the script. Status:', request.status);
        return null;
    }
}

export function gu_logout(dispatch, reduxCountryLanguageKey) {
    dispatch(userAction.logout()).then(() => {
        if (!window.isAgent && !window.isAffiliate) {
            if (window.SPL_LiteApp.isZT828LiteApp()) {
                window.SPL_LiteApp.onLogoutTapped();
            }
        }

        let path = '/';
        let langKey = '';
        let pathname = window.location.pathname;
        let pathArray = pathname.split('/');

        if (pathArray && pathArray.length >= 2 && pathArray[1].indexOf('-') !== -1) {
            langKey = pathArray[1];
        } else if (reduxCountryLanguageKey) {
            langKey = reduxCountryLanguageKey.toLowerCase().replace('_', '-');
        } else if (window && window.countryLanguageKey) {
            langKey = window.countryLanguageKey.toLowerCase().replace('_', '-');
        }

        path = '/' + langKey;

        window.location.href = path + (window.isAffiliate ? '/aff' : '');
    });
}

export function gu_getSubmenuMenu(language, menu) {
    const returnTranslation = menu.translation || menu.content || menu.name;

    return returnTranslation[language] || returnTranslation;
}

export function gu_filterCommonKey(parentElement = false, childElement = false, filterSettings = {}) {
    let params = {
        loop: filterSettings.loop || false, // use for filter multi key, eg: web: { THB: { en: "test" } }
        baseOnUserCurrency: filterSettings.baseOnUserCurrency || false, // currency will get member currency
        customFilterKeys: filterSettings.customFilterKeys || [], // use for filter special key
    };

    return window.SPL_Content.filterCommonKeyHandler(gu_getNestedValue(parentElement, childElement), params);
}
