import { walletConstant } from '../constant';
export const walletAction = {
  updateLoadWallet
};

function updateLoadWallet(loadWallet) {
  return dispatch => {
    dispatch({ type: walletConstant.UDPATE_LOAD_WALLET, payload: { loadWallet } });
  };
}
