import React from 'react'

const Loading = (props) => {
  return (
    <div className="loading-container">
      <div className="loading">
        <img src="/public/html/images/common_img/puff.svg" />
      </div>
    </div>
  )
}

export default Loading