import { screenConstants } from '../constant';
import { isMobile } from 'react-device-detect';

export const screenAction = {
    updateScreenSize
};

function updateScreenSize(width, height, prevMobile) {
    return dispatch => {
        let isMobileScreen = false;
        let viewType = 'web';

        if (width <= 768) {
            isMobileScreen = true;
        } else {
            isMobileScreen = false;
        }
        if (isMobileScreen) {
            viewType = 'mobile';
        }
        setTimeout(() => {
            let viewPort = window.SPL_Other.getMeta('viewport');
            let content = viewPort.getAttribute('content');
            if (prevMobile !== isMobileScreen && content !== 'width=1024') {
                window.location.reload();
            } else {
                dispatch({ type: screenConstants.UPDATE_SCREEN_SIZE, payload: { width, height, isMobile: isMobileScreen, viewType, isMobileAgent: isMobile } });
            }
        }, 300);

    };
}
