import { depositConstant } from '../constant';

const initialState = {
  submissionData: {},
};

export function depositSubmission(state = initialState, action) {
  switch (action.type) {
    case depositConstant.UPDATE_DEPOSIT_SUBMISSION:
      return {
        ...state,
        submissionData: { ...action.payload }
      };

    default:
      return state;
  }
}
