// library
import React, { useState, lazy, Suspense, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// logic
import { POPUP_TYPE } from '@constants';
import { popupAction } from '@redux/action';
import { useGetCurrentTranslation } from '@hooks/general-hook';

// components
import styles from './enter-pin-popup.module.scss';
const FormField = lazy(() => import('@components/molecules/form-field/form-field'));
const Popup = lazy(() => import('@components/organisms/popup/popup'));
const PopupBody = lazy(() => import('@components/organisms/popup/popup').then((module) => ({ default: module.PopupBody })));
const PopupHeader = lazy(() => import('@components/organisms/popup/popup').then((module) => ({ default: module.PopupHeader })));
const Button = lazy(() => import('@components/atoms/button/button'));

const EnterPinPopup = () => {
    const { t } = useTranslation('enterPin');
    const dispatch = useDispatch();
    const _popupType = POPUP_TYPE.PIN_ENTRY;

    // redux
    const popupReducer = useSelector((state) => state.popupReducer);
    const portal = useSelector((state) => state.portal);
    const language = useSelector((state) => state.language);
    const _popupConfig = popupReducer?.config?.[_popupType];

    // hooks
    const { translationData } = useGetCurrentTranslation('transaction');

    // state
    const initializeFields = () => ({
        pinNumber: { value: '', isMandatory: true, errMsg: '' },
    });

    const [fields, setFields] = useState(initializeFields);
    const [isSubmitting, setIsSubmitting] = useState(false);

    // function
    const onRequestClose = () => {
        dispatch(popupAction.togglePopUp(POPUP_TYPE.PIN_ENTRY, false));
        dispatch(popupAction.setEnteredPin(''));
    };

    const onInputFieldChange = (event) => {
        const { name, value } = event.target;
        const truncatedValue = value?.slice(0, 6);
        setFields((prevFields) => ({
            ...prevFields,
            [name]: { ...prevFields?.[name], value: truncatedValue },
        }));
    };

    const handleKeyPress = (event) => {
        const isValidKey = /^\d$/.test(event?.key);

        const hasSelection = event.target.selectionStart !== event.target.selectionEnd;

        if (isValidKey && hasSelection) {
            event.target.value = '';
            return;
        }

        if (!isValidKey) {
            event.preventDefault();
        }
        if (event?.target?.value.length >= 6 && event?.key !== 'Backspace') {
            event.preventDefault();
        }
    };

    const isSubmitBtnDisabled = () => {
        const _pinNumbers = fields?.pinNumber?.value;
        const isPinNumbersValid = _pinNumbers?.length === 6;

        // portal?.isSubmitting is triggered from redux.
        const isDisabled = isSubmitting || !isPinNumbersValid || portal?.isSubmitting;
        return isDisabled;
    };

    const onSubmitBtnClick = () => {
        const _submitSuccessCb = popupReducer?.submitSuccessCb;
        setIsSubmitting(true);
        dispatch(popupAction.setEnteredPin(fields?.pinNumber?.value)).then(() => {
            _submitSuccessCb && _submitSuccessCb();
            setIsSubmitting(false);
        });
    };

    const _popupTitle = useMemo(() => {
        let text = '';
        if (_popupConfig?.gaEnabled) {
            text = t('enterPin:enterPin.title.verifyIdentity', 'Verify Your Identity');
        }

        if (_popupConfig?.sixPinEnabled) {
            text = translationData?.sixDigitPin?.enterPinCodeTitle?.[language?.key] || 'Enter PIN Code';
        }

        return text;
    }, [_popupConfig?.gaEnabled, _popupConfig?.sixPinEnabled, translationData, language?.key, t]);

    const _pinNumberLabel = useMemo(() => {
        let text = '';
        if (_popupConfig?.gaEnabled) {
            text = t('enterPin:enterPin.field.pinNumber.label', 'Enter the code from your Google Authenticator');
        }

        if (_popupConfig?.sixPinEnabled) {
            text = translationData?.sixDigitPin?.enterPinCodeFieldName?.[language?.key] || 'Please enter your personal PIN';
        }

        return text;
    }, [_popupConfig?.gaEnabled, _popupConfig?.sixPinEnabled, translationData, language?.key, t]);

    const handlePaste = (event) => {
        // Get the pasted text
        const paste = (event.clipboardData || window.clipboardData).getData('text');
        // Check if the pasted text is not numeric
        if (!/^\d+$/.test(paste)) {
            event.preventDefault();
        }
    };
  
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Popup isOpen={popupReducer?.items?.[_popupType]} className={`${styles.pinModalPopup}`}>
                <PopupHeader className={styles.pinModalPopupHeader} onRequestClose={onRequestClose} popupTitle={_popupTitle} />

                <PopupBody className={`${styles.pinModalPopupBody}`}>
                    <FormField
                        label={_pinNumberLabel}
                        type={'text'}
                        onChange={onInputFieldChange}
                        onKeyPress={handleKeyPress}
                        name={'pinNumber'}
                        value={fields?.pinNumber?.value}
                        errorMessage={fields?.pinNumber?.errMsg}
                        isMandatory={true}
                        onPaste={handlePaste}
                    />

                    <SubmitButton onClick={onSubmitBtnClick} disabled={isSubmitBtnDisabled()} text={t('enterPin:enterPin.button.submit', 'Submit')} />
                </PopupBody>
            </Popup>
        </Suspense>
    );
};

export default EnterPinPopup;

export const SubmitButton = ({ onClick, text, disabled }) => {
    return (
        <div className='standard-button-container'>
            <Button className={`standard-button standard-submit-button ${styles.submitBtn}`} onClick={onClick} disabled={disabled}>
                <span>{text}</span>
            </Button>
        </div>
    );
};
