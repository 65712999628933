import React from 'react';
const Loading = (props) => {
  // useEffect(() => {
  //   const logo = document.querySelectorAll('#logo path');

  //   for (let i = 0; i < logo.length; i++) {
  //     console.log(`Letter ${i} is ${logo[i].getTotalLength()}`);
  //   }
  // }, []);
  return (
    <div className='loading-container'>
      <div className='loading'>
        <svg id='logo' width='595' height='120' viewBox='0 0 450 94' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M35.32,12.29a.79.79,0,0,1-.68-.27,8.28,8.28,0,0,0-4.84-2,12.41,12.41,0,0,0-1.44-.06,9.24,9.24,0,0,0-1.85.13,9.52,9.52,0,0,0-2.76.88,5.38,5.38,0,0,0-2.69,3.31,3.22,3.22,0,0,0,1.13,3.54,24.37,24.37,0,0,0,4.6,3.07,48.3,48.3,0,0,1,5.91,4,9.53,9.53,0,0,1,3,4.35,10.75,10.75,0,0,1-.2,6.58,17.2,17.2,0,0,1-6,8.91,20.43,20.43,0,0,1-10.17,4.39,24.61,24.61,0,0,1-3.78.34,21.34,21.34,0,0,1-6-.81A17.21,17.21,0,0,1,4,46.08,14,14,0,0,1,.1,42,1.07,1.07,0,0,1,0,41.25a1.33,1.33,0,0,1,.85-1L8.1,36.46a1.38,1.38,0,0,1,.66-.14.9.9,0,0,1,.76.27,9.62,9.62,0,0,0,5,3,13.18,13.18,0,0,0,3.76.47A11.5,11.5,0,0,0,20.3,40a8.63,8.63,0,0,0,4.08-1.62,4.81,4.81,0,0,0,1.56-2.5,2.89,2.89,0,0,0-.55-2.67,8.27,8.27,0,0,0-1.86-1.85c-.61-.41-1.75-1.13-3.45-2.16a49.14,49.14,0,0,1-5.87-3.85,9.58,9.58,0,0,1-3.07-4.19A9.64,9.64,0,0,1,11.25,15,15.74,15.74,0,0,1,12,12.76a19.12,19.12,0,0,1,8.75-9.58A23.46,23.46,0,0,1,27.34,1a27.59,27.59,0,0,1,4-.34c.51,0,1.26.05,2.25.14A17.57,17.57,0,0,1,39.69,2.6,11.21,11.21,0,0,1,44,6.42a1.07,1.07,0,0,1,0,.94,1.39,1.39,0,0,1-.72.81L36,12.09A1.4,1.4,0,0,1,35.32,12.29Z' stroke='#66b2ed' strokeWidth='5' />
          <path
            d='M82.34,2.64A15.61,15.61,0,0,1,88.59,8a17.32,17.32,0,0,1,3,7.86,22.67,22.67,0,0,1-.83,9.22,30.49,30.49,0,0,1-4.5,9.21,38.27,38.27,0,0,1-7.55,7.94,34,34,0,0,1-9.32,5.3,28.25,28.25,0,0,1-9.85,1.85,20.29,20.29,0,0,1-8.78-1.85,16,16,0,0,1-6.33-5.3,17.5,17.5,0,0,1-2.92-7.94,23,23,0,0,1,.85-9.21,31.32,31.32,0,0,1,4.48-9.22A36.16,36.16,0,0,1,54.31,8a35.37,35.37,0,0,1,9.4-5.33A27.66,27.66,0,0,1,73.57.75,19.87,19.87,0,0,1,82.34,2.64Zm-6.17,8.47A12.22,12.22,0,0,0,70.9,10a17.15,17.15,0,0,0-6,1.12,20.23,20.23,0,0,0-5.62,3.2,23.46,23.46,0,0,0-4.63,4.93,20.17,20.17,0,0,0-2.79,5.81,14.66,14.66,0,0,0-.56,5.8,10.75,10.75,0,0,0,1.77,4.93A9.08,9.08,0,0,0,56.85,39a12.38,12.38,0,0,0,5.34,1.11A17,17,0,0,0,68.11,39a20.16,20.16,0,0,0,5.62-3.21,22.14,22.14,0,0,0,4.7-4.93,20.11,20.11,0,0,0,2.79-5.8,14.89,14.89,0,0,0,.56-5.81,10.48,10.48,0,0,0-1.84-4.93A9.19,9.19,0,0,0,76.17,11.11Z'
            stroke='#66b2ed'
            strokeWidth='5'
          />
          <path
            d='M102,1.76a1.39,1.39,0,0,1,.9-.34h16a11.63,11.63,0,0,1,6.68,1.89,9.39,9.39,0,0,1,3.8,5.13,11.93,11.93,0,0,1-.08,7A18.83,18.83,0,0,1,124.85,23l-.77.75.52.6q3.34,4.32,1.62,10.27a18.09,18.09,0,0,1-4,7,21.76,21.76,0,0,1-6.77,5.13,17.8,17.8,0,0,1-7.85,1.89H89.2a.77.77,0,0,1-.7-.34.94.94,0,0,1-.08-.81l13-45A1.58,1.58,0,0,1,102,1.76Zm3.52,18.77h7.85A6.74,6.74,0,0,0,117.45,19,6.83,6.83,0,0,0,120,15.46a3.9,3.9,0,0,0-.49-3.58,3.83,3.83,0,0,0-3.26-1.48H108.4ZM99.93,39.7h10.29a6.74,6.74,0,0,0,4.12-1.49,6.84,6.84,0,0,0,2.56-3.57,3.94,3.94,0,0,0-.45-3.62,3.85,3.85,0,0,0-3.3-1.45H102.86Z'
            stroke='#66b2ed'
            strokeWidth='5'
          />
          <path
            d='M155.88,40a.87.87,0,0,1,.12.81l-1.94,6.68a1.66,1.66,0,0,1-.54.81,1.53,1.53,0,0,1-1,.34H127.86a.86.86,0,0,1-.73-.34.88.88,0,0,1-.12-.81l13-45a1.45,1.45,0,0,1,.57-.78,1.61,1.61,0,0,1,.93-.3h24.68a.94.94,0,0,1,.75.3.81.81,0,0,1,.12.78l-2,6.75a1.49,1.49,0,0,1-.58.81,1.51,1.51,0,0,1-.93.34H147l-2.95,10.19h15a.81.81,0,0,1,.71.31.84.84,0,0,1,.09.77l-2,6.75a1.43,1.43,0,0,1-.54.78,1.45,1.45,0,0,1-.89.3h-15l-2.94,10.2h16.62A.83.83,0,0,1,155.88,40Z'
            stroke='#66b2ed'
            strokeWidth='5'
          />
          <path d='M180.18,48.56a1.4,1.4,0,0,1-.9.34l-6.95,0a.78.78,0,0,1-.71-.34,1,1,0,0,1-.08-.8L182.1,10.61l-12.1.05a.77.77,0,0,1-.7-.33.94.94,0,0,1-.09-.81l1.9-6.69a1.66,1.66,0,0,1,.55-.82,1.4,1.4,0,0,1,.89-.34l33.31-.15a.94.94,0,0,1,.79.3,1,1,0,0,1,.07.85l-1.9,6.69a1.49,1.49,0,0,1-.58.81,1.51,1.51,0,0,1-.93.34l-12,.06L180.72,47.74A1.64,1.64,0,0,1,180.18,48.56Z' stroke='#66b2ed' strokeWidth='5' />
          <path
            d='M244.56,17.15c-.68,19.59-13.45,34.56-30.26,34.56a22.88,22.88,0,0,1-7.11-1.09l-.14-7.55a23,23,0,0,0,6.84,1c8.09,0,15.78-5.78,19.14-16.26a19.67,19.67,0,0,1-12.82,4.62c-7.14,0-11.38-5.37-11.1-13.33.4-11.43,9-19.12,20.6-19.12C239.24,0,245,6.12,244.56,17.15Zm-9.69-1C235,11.23,232.63,8,228.42,8c-5.11,0-9.06,4.15-9.27,10.14-.16,4.56,2.32,7.62,6.47,7.62C230.72,25.72,234.66,22,234.87,16.13Z'
            stroke='#66b2ed'
            strokeWidth='5'
          />
          <path
            d='M285.05,17.15c-.69,19.59-13.46,34.56-30.26,34.56a22.83,22.83,0,0,1-7.11-1.09l-.14-7.55a22.83,22.83,0,0,0,6.83,1c8.1,0,15.78-5.78,19.14-16.26a19.65,19.65,0,0,1-12.81,4.62c-7.15,0-11.38-5.37-11.1-13.33C250,7.69,258.63,0,270.2,0,279.73,0,285.43,6.12,285.05,17.15Zm-9.7-1c.17-4.9-2.23-8.17-6.45-8.17-5.1,0-9.06,4.15-9.27,10.14-.16,4.56,2.32,7.62,6.47,7.62C271.21,25.72,275.15,22,275.35,16.13Z'
            stroke='#66b2ed'
            strokeWidth='5'
          />
        </svg>
      </div>
    </div>
  );
};

export default Loading;
