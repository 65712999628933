// library
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// core / logic / helper
import { UPDATE_MEMBER_INFO_FIELD as UMF, POPUP_TYPE, OTP_CONTACT_TYPE } from '@constants';

/**
 *
 * @param {Object} googleAuthKey -- upon receive data, then auto bind "key" field
 */
export const useGetUpdateMemberInfoFields = () => {
    const _popupType = POPUP_TYPE.EDIT_VERIFY_AUTH;

    // redux
    const popupReducer = useSelector((state) => state.popupReducer);
    const _popupConfig = popupReducer?.config?.[_popupType];
    const _selectedAuthContact = _popupConfig?.selectedAuthContact;

    const updateMemberInfoFields = () => ({
        ...(_selectedAuthContact === OTP_CONTACT_TYPE.EMAIL && {
            [UMF.EMAIL]: { value: '', isMandatory: true, errMsg: '' },
        }),
        ...(_selectedAuthContact === OTP_CONTACT_TYPE.PHONE && {
            [UMF.PHONE]: { value: '', isMandatory: true, errMsg: '' },
        }),
        [UMF.GA]: { value: '', isMandatory: true, errMsg: '' },
        [UMF.OTP]: { value: '', isMandatory: true, errMsg: '' },
    });

    const [fields, setFields] = useState(updateMemberInfoFields);

    const [changedField, setChangedField] = useState(null);

    const onInputFieldChange = (event) => {
        const { name, value } = event.target;
        setFields((prevFields) => ({
            ...prevFields,
            [name]: { ...prevFields?.[name], value: value },
        }));
        setChangedField(name);
    };
    useEffect(() => {
        if (!fields?.[changedField]?.value) {
            return;
        }
        switch (changedField) {
            case UMF.EMAIL:
                validateEmail();
                break;
            default:
                break;
        }
    }, [fields?.[changedField]?.value]);

    const validateEmail = async () => {
        const errMsg = await window.SPL_Register.validateEmail(fields?.[UMF.EMAIL]?.value, window.merchantCode);
        setFields((prevFields) => ({
            ...prevFields,
            [UMF.EMAIL]: { ...prevFields?.[UMF.EMAIL], errMsg: errMsg?.[0] || '' },
        }));
    };

    return { fields, setFields, onInputFieldChange };
};
