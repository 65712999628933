// library
import React, { useState, lazy, Suspense, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// logic
import { POPUP_TYPE, UPDATE_MEMBER_INFO_FIELD as UMF, OTP_CONTACT_TYPE, OTP_VERIFICATION_TYPE } from '@constants';
import { popupAction, userAction } from '@redux/action';
import { useRequestOtp, useGetOtpContactAndMethod } from '@hooks/otp-hook';
import { useGetUpdateMemberInfoFields } from './edit-verify-auth-popup-hook';
import { pu_getTelCodeFromPhoneNumber } from '@utils/phone-util';
import notification from '@utils/notification';

// components
import styles from './edit-verify-auth-popup.module.scss';
const PhoneField = lazy(() => import('../../molecules/phone-field/phone-field'));
const FormField = lazy(() => import('@components/molecules/form-field/form-field'));
const Popup = lazy(() => import('@components/organisms/popup/popup'));
const PopupBody = lazy(() => import('@components/organisms/popup/popup').then((module) => ({ default: module.PopupBody })));
const PopupHeader = lazy(() => import('@components/organisms/popup/popup').then((module) => ({ default: module.PopupHeader })));
const Button = lazy(() => import('@components/atoms/button/button'));
const PhoneOtpMethodSelection = lazy(() => import('@components/molecules/phone-otp-method-selection/phone-otp-method-selection'));
const OTPHelper = lazy(() => import('@components/molecules/otp-helper/otp-helper'));
const OtpFormField = lazy(() => import('@components/molecules/otp-form-field/otp-form-field'));

const EditVerifyAuthPopup = () => {
    const { t } = useTranslation(['otp', 'updateMemberInfo', 'global']);
    const dispatch = useDispatch();
    const _popupType = POPUP_TYPE.EDIT_VERIFY_AUTH;
    const _verificationType = OTP_VERIFICATION_TYPE.UPDATE_MEMBER_INFO;

    // redux
    const language = useSelector((state) => state.language);
    const user = useSelector((state) => state.user);
    const popupReducer = useSelector((state) => state.popupReducer);
    const _popupConfig = popupReducer?.config?.[_popupType];
    const _selectedAuthContact = _popupConfig?.selectedAuthContact;

    // state
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedTelCode, setSelectedTelCode] = useState(null);

    // hooks
    const { fields, setFields, onInputFieldChange } = useGetUpdateMemberInfoFields();
    const { preferMethod, phoneVerificationMethods } = useGetOtpContactAndMethod({
        module: UMF?.BIND_AUTHENTICATOR,
    });
    // ===== hook useRequestOtp config =====
    const _otpApiParam = {
        contactType: _selectedAuthContact,
        verificationType: _verificationType,
        verificationMethod: preferMethod || _selectedAuthContact, //_selectedAuthContact serve as fallback, usually only when choose email preferMethod will be null only.
        currencyCode: user?.account?.currency,
        language: language.countryLanguageKey,
        ...(_selectedAuthContact === OTP_CONTACT_TYPE?.EMAIL && {
            email: fields?.[UMF.EMAIL]?.value,
        }),
        ...(_selectedAuthContact === OTP_CONTACT_TYPE?.PHONE && {
            phone: selectedTelCode + fields?.[UMF.PHONE]?.value,
        }),
    };
    const onRequestOtpSuccess = () => {
        setFields((prevFields) => ({
            ...prevFields,
            [UMF.OTP]: { ...prevFields[UMF.OTP], errMsg: '' },
        }));
    };
    const onRequestOtpFail = (err) => {
        const _errMsg = t(`otp:otp.request.message.${err?.key}`, err?.message, { attemptCount: err?.maxAttempt });

        setFields((prevFields) => ({
            ...prevFields,
            [UMF.OTP]: { ...prevFields[UMF.OTP], errMsg: _errMsg },
        }));
    };
    const { requestOtp, isRequestingOtp, isOtpRequested, otpCountdownTimer, verifyAttemptCount } = useRequestOtp({
        splParams: { otpOptions: { otpType: _verificationType, isPublic: false }, data: _otpApiParam },
        onRequestOtpSuccess, // Callback on successful OTP request
        onRequestOtpFail, // Callback on failed OTP request
    });
    // ===== useRequestOtp config end =====

    useEffect(() => {
        getMemberTelCode();
    }, []);

    // function
    const onRequestClose = () => {
        dispatch(popupAction.togglePopUp(_popupType, false));
        dispatch(popupAction.setEnteredPin(''));
    };

    const getMemberTelCode = () => {
        const memberPhone = user?.account?.phone;
        const extractedTelCode = pu_getTelCodeFromPhoneNumber(memberPhone);
        setSelectedTelCode(`+${extractedTelCode}`);
    };

    const isOtpButtonDisabled = () => {
        // preferContact === OCT.PHONE && !preferMethod (when contact is phone, need choose verification method first)
        const isMandatoryFieldValid = fields?.[UMF?.[_selectedAuthContact]]?.value && !fields?.[UMF?.[_selectedAuthContact]]?.errMsg;
        const isDisabled = otpCountdownTimer > 0 || isRequestingOtp || (_selectedAuthContact === OTP_CONTACT_TYPE.PHONE && !preferMethod) || !isMandatoryFieldValid;
        return isDisabled;
    };

    const isSubmitBtnDisabled = () => {
        const hasEmptyField = Object.values(fields).some((field) => !field.value);

        const hasErrorBlocking = Object.entries(fields).some(([key, field]) => {
            // if is otp, skip checking
            const hasError = key !== UMF.OTP && key !== UMF.GA && field.errMsg;
            return hasError;
        });

        const isDisabled = hasEmptyField || hasErrorBlocking || isSubmitting || !isOtpRequested;
        return isDisabled;
    };

    const onSubmitBtnClick = async () => {
        setIsSubmitting(true);

        const _processedContactValue = _selectedAuthContact === OTP_CONTACT_TYPE.PHONE ? selectedTelCode + fields?.[UMF.PHONE]?.value : fields?.[UMF.EMAIL].value;
        const apiParam = {
            gaCode: fields?.[UMF.GA].value,
            otp: fields?.[UMF.OTP].value,
            type: _selectedAuthContact,
            value: _processedContactValue,
            verificationMethod: preferMethod || _selectedAuthContact, // preferContact is fallback only, can don't need de, but just in case =x
        };

        try {
            await window.SPL_Member.updateMemberContactInfo(apiParam);
            setIsSubmitting(false);
            const updatedAccount = {
                ...user?.account,
                [_selectedAuthContact?.toLowerCase()]: _processedContactValue,
            };
            dispatch(userAction.updateCertainUser(updatedAccount));
            dispatch(popupAction.togglePopUp(_popupType, false));
        } catch (err) {
            if (err) {
                const { errMsg, apiData } = err;
                if (apiData) {
                    const { googleAuth, otpAuth } = apiData;
                    if (!googleAuth) {
                        setFields((prevFields) => ({
                            ...prevFields,
                            [UMF.GA]: { ...prevFields[UMF.GA], errMsg: errMsg },
                        }));
                    } else if (!otpAuth) {
                        setFields((prevFields) => ({
                            ...prevFields,
                            [UMF.OTP]: { ...prevFields[UMF.OTP], errMsg: errMsg },
                        }));
                    }
                } else {
                    notification.showNotification('error', err?.message || err?.detail, { autoClose: 2000 });
                }
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Popup isOpen={popupReducer?.items?.[_popupType]} className={`${styles.editVerifyAuthPopup}`}>
                <PopupHeader
                    onRequestClose={onRequestClose}
                    popupTitle={t(`updateMemberInfo:updateMemberInfo.title.update-verify-${_selectedAuthContact?.toLowerCase()}`, `Update and Verify ${_selectedAuthContact}`)}
                />

                <PopupBody className={`${styles.editVerifyAuthBody}`}>
                    <div className='standard-form-container'>
                        {_selectedAuthContact === OTP_CONTACT_TYPE.EMAIL && (
                            <FormField
                                label={t('updateMemberInfo:updateMemberInfo.field.email.label', 'Enter the new Email Address')}
                                placeholder={t('updateMemberInfo:updateMemberInfo.field.email.placeholder', 'New Email Address')}
                                type={'text'}
                                onChange={onInputFieldChange}
                                name={UMF.EMAIL}
                                value={fields?.[UMF.EMAIL]?.value}
                                errorMessage={t(fields?.[UMF.EMAIL]?.errMsg, fields?.[UMF.EMAIL]?.errMsg)}
                                isMandatory={true}
                            />
                        )}

                        {_selectedAuthContact === OTP_CONTACT_TYPE.PHONE && (
                            <>
                                <PhoneField
                                    label={t('updateMemberInfo:updateMemberInfo.field.phone.label', 'Enter new mobile number')}
                                    placeholder={t('updateMemberInfo:updateMemberInfo.field.phone.placeholder', 'Mobile Number')}
                                    type={'text'}
                                    onChange={onInputFieldChange}
                                    name={UMF.PHONE}
                                    value={fields?.[UMF.PHONE]?.value}
                                    errorMessage={t(fields?.[UMF.PHONE]?.errMsg, fields?.[UMF.PHONE]?.errMsg)}
                                    telCode={selectedTelCode}
                                    isMandatory={true}
                                />
                                <PhoneOtpMethodSelection
                                    selectedLabel={preferMethod || t('otp:otp.pleaseSelect', 'Please Select')}
                                    phoneVerificationMethods={phoneVerificationMethods}
                                    preferMethod={preferMethod}
                                />
                            </>
                        )}

                        <OtpFormField
                            label={t(`otp:otp.field.${UMF.OTP}.label`, 'Enter the OTP')}
                            placeholder={t(`otp:otp.field.${UMF.OTP}.placeholder`, 'Enter OTP Code')}
                            name={UMF.OTP}
                            value={fields?.[UMF.OTP]?.value}
                            onChange={onInputFieldChange}
                            disabled={isOtpButtonDisabled()}
                            onClick={requestOtp}
                            buttonText={t('otp:otp.button.getOtp', 'Get OTP') + ` ${otpCountdownTimer > 0 ? otpCountdownTimer : ''}`}
                            errMsg={t(fields?.[UMF.OTP]?.errMsg, fields?.[UMF.OTP]?.errMsg)}
                            verifyAttemptMsg={
                                isOtpRequested && !fields?.[UMF.OTP]?.errMsg && t('otp:otp.request.message.requestAnotherCode', { attemptCount: verifyAttemptCount })
                            }
                        />

                        <OTPHelper className={styles.otpHelper} />

                        <FormField
                            label={t('updateMemberInfo:updateMemberInfo.field.gaCode.label', 'Enter the code from your Google Authenticator')}
                            type={'text'}
                            onChange={onInputFieldChange}
                            name={UMF.GA}
                            value={fields?.[UMF.GA]?.value}
                            errorMessage={t(fields?.[UMF.GA]?.errMsg, fields?.[UMF.GA]?.errMsg)}
                            isMandatory={true}
                        />

                        <SubmitButton
                            onClick={onSubmitBtnClick}
                            onCancel={onRequestClose}
                            disabled={isSubmitBtnDisabled()}
                            submitText={t('updateMemberInfo:updateMemberInfo.button.submit', 'Submit')}
                            cancelText={t('updateMemberInfo:updateMemberInfo.button.cancel', 'Cancel')}
                        />
                    </div>
                </PopupBody>
            </Popup>
        </Suspense>
    );
};

export default EditVerifyAuthPopup;

export const SubmitButton = ({ onClick, onCancel, disabled, ...props }) => {
    return (
        <div className={`standard-button-container ${styles.buttonContainer}`}>
            <Button className={`standard-button standard-submit-button ${styles.submitBtn}`} onClick={onClick} disabled={disabled}>
                <span>{props.submitText}</span>
            </Button>
            <Button className={`standard-button standard-submit-button ${styles.cancelBtn}`} onClick={onCancel}>
                <span>{props.cancelText}</span>
            </Button>
        </div>
    );
};
