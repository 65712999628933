import { portalAction } from 'root/redux/action';
import notification from 'root/utils/notification';

const core = {
    getCorrectComponent(allComponent, type, viewType) {
        let templateKey = core.getTemplateKey(type);
        allComponent = core.getCorrectComponents(allComponent, viewType);
        if (templateKey && window[templateKey] && allComponent[window[templateKey]]) {
            return allComponent[window[templateKey]];
        } else {
            return allComponent['default'];
        }
    },

    getCorrectComponents(allComponent, viewType) {
        let isMobile = false;
        if (viewType === 'mobile') {
            isMobile = true;
        }

        if (allComponent.desktop && allComponent.mobile) {
            return isMobile ? allComponent.mobile : allComponent.desktop;
        } else {
            // fallback use old implementation
            return allComponent;
        }
    },

    getTemplateKey(type, viewType) {
        if (viewType === 'mobile') {
            return this.getMobileTemplateKey(type);
        } else {
            return this.getDesktopTemplateKey(type);
        }
    },

    getDesktopTemplateKey(type) {
        switch (type) {
            default:
                return null;
        }
    },

    getMobileTemplateKey(type) {
        switch (type) {
            default:
                return null;
        }
    },

    replaceAll(str, search, replacement) {
        return str.replace(new RegExp(search, 'g'), replacement);
    },

    portalIsLoading(props, flag) {
        props.dispatch(portalAction.portalIsLoading(flag));
    },

    // getLoggedInLanguage(props) {
    //     const { user, i18n, dispatch, language } = props;
    //     window.SPL_Content.getLanguageList().then((data) => {
    //         for (let i = 0, l = data.length; i < l; i++) {
    //             const currentLanguage = data[i];
    //             if (currentLanguage.currency === user.account.currency) {
    //                 let countryLanguageKey = currentLanguage.languages[0].countryLanguageKey;
    //                 currentLanguage.languages.forEach((e) => {
    //                     if (e.countryLanguageKey === language.countryLanguageKey) countryLanguageKey = e.countryLanguageKey;
    //                 });

    //                 let key = countryLanguageKey.split('_')[0].toLowerCase();
    //                 if (key === 'zh') key = 'zh-CN';

    //                 i18n.changeLanguage(key);
    //                 dispatch(languageAction.changeLanguage({ key, countryLanguageKey }));
    //                 break;
    //             }
    //         }
    //     });
    // },

    copy(t, text) {
        let copied = 'transaction:transaction.withdrawal.copied';
        let successful = window.SPL_Other.copyToClipboard(text);
        if (successful) {
            notification.showNotification('info', t(copied, 'Copied'), { autoClose: 2000 });
        }
    },
};

export default core;
