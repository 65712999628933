import { popupConstants } from '../constant';
export const popupAction = {
    togglePopUp,
    setSubmitSuccessCb,
    setEnteredPin,
    setItemConfig,
};

function togglePopUp(item, isVisible) {
    return (dispatch) => {
        dispatch({ type: popupConstants.TOGGLE_POPUP, payload: { item, isVisible } });
    };
}

function setSubmitSuccessCb(fn) {
    return (dispatch) => {
        dispatch({ type: popupConstants.SET_SUBMIT_SUCCESS_CB, payload: { fn } });
    };
}

function setEnteredPin(enteredPin) {
    return (dispatch) => {
        return new Promise((resolve) => {
            dispatch({ type: popupConstants.SET_ENTERED_PIN, payload: { enteredPin } });
            resolve();
        });
    };
}

function setItemConfig(item, config) {
    return (dispatch) => {
        dispatch({ type: popupConstants.SET_ITEM_CONFIG, payload: { item, config } });
    };
}
