// library
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// core / logic / helper
import { GA_FIELD, MODULE_TYPE as MT } from '@constants';

export const useGetMemberGoogleAuthData = () => {
    const [googleAuthData, setGoogleAuthData] = useState(null);

    useEffect(() => {
        const fetchGoogleAuthData = async () => {
            try {
                const res = await window.SPL_Member.getMemberGoogleAuth();
                setGoogleAuthData(res);
            } catch (err) {
                //
            }
        };

        fetchGoogleAuthData();
    }, []);

    return { googleAuthData };
};

/**
 *
 * @param {Object} googleAuthKey -- upon receive data, then auto bind "key" field
 */
export const useGetGoogleAuthFields = ({ googleAuthKey }) => {
    const initializeGaFields = () => ({
        [GA_FIELD.KEY]: { value: '', isMandatory: true, errMsg: '' },
        [GA_FIELD.CODE]: { value: '', isMandatory: true, errMsg: '' },
        [GA_FIELD.OTP]: { value: '', isMandatory: true, errMsg: '' },
    });

    const [fields, setFields] = useState(initializeGaFields);
    const [changedField, setChangedField] = useState(null);

    useEffect(() => {
        if (googleAuthKey) {
            setFields((prevFields) => ({
                ...prevFields,
                [GA_FIELD.KEY]: { ...prevFields[GA_FIELD.KEY], value: googleAuthKey },
            }));
        }
    }, [googleAuthKey]);

    const onInputFieldChange = (event) => {
        const { name, value } = event.target;
        setFields((prevFields) => ({
            ...prevFields,
            [name]: { ...prevFields?.[name], value: value },
        }));
        setChangedField(name);
    };
    useEffect(() => {
        if (!fields?.[changedField]?.value) {
            return;
        }
        switch (changedField) {
            default:
                break;
        }
    }, [changedField, fields]);

    return { fields, setFields, onInputFieldChange };
};

export const useGetGoogleAuthVerifyStatus = () => {
    const bindAuthenticatorAuthSettings = useSelector((state) => state?.authSettingsReducer?.processedData?.[MT.BIND_AUTHENTICATOR]);

    const [isReqPreVerify, setIsReqPreVerify] = useState(false);

    useEffect(() => {
        if (bindAuthenticatorAuthSettings?.authMethod?.length === 0 || !bindAuthenticatorAuthSettings?.authMethod) {
            setIsReqPreVerify(true);
        }
    }, [bindAuthenticatorAuthSettings]);

    return {
        isReqPreVerify,
    };
};
