import React, { Fragment, useState, lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SVG from 'react-inlinesvg';

// logic
import { GA_FIELD as GAF } from '@constants';
import { popupAction } from '@redux/action';
import { POPUP_TYPE, MODULE_TYPE as MT, OTP_CONTACT_TYPE as OCT, OTP_VERIFICATION_TYPE as OVT } from '@constants';
import { useCommonHrefRedirect } from '@hooks/general-hook';
import core from '@utils/core';
import { useRequestOtp, useGetOtpContactAndMethod } from '@hooks/otp-hook';
import { authSettingsAction } from '@redux/action';
import { useGetGoogleAuthFields, useGetGoogleAuthVerifyStatus, useGetMemberGoogleAuthData } from './google-auth-popup-hook';
import notification from '@utils/notification';

// components
import styles from './google-auth-popup.module.scss';
import { ou_filterMasterSwitchAuthMethods } from '@utils/otp-util';
const Popup = lazy(() => import('@components/organisms/popup/popup'));
const PopupBody = lazy(() => import('@components/organisms/popup/popup').then((module) => ({ default: module.PopupBody })));
const PopupHeader = lazy(() => import('@components/organisms/popup/popup').then((module) => ({ default: module.PopupHeader })));
const FormField = lazy(() => import('@components/molecules/form-field/form-field'));
const Text = lazy(() => import('@components/atoms/text/text'));
const Button = lazy(() => import('@components/atoms/button/button'));
const AuthOtpTabs = lazy(() => import('@components/molecules/auth-otp-tabs/auth-otp-tabs'));
const AuthOtpTab = lazy(() => import('@components/molecules/auth-otp-tabs/auth-otp-tabs').then((module) => ({ default: module.AuthOtpTab })));
const OtpFormField = lazy(() => import('@components/molecules/otp-form-field/otp-form-field'));
const OTPHelper = lazy(() => import('@components/molecules/otp-helper/otp-helper'));
const PhoneOtpMethodSelection = lazy(() => import('@components/molecules/phone-otp-method-selection/phone-otp-method-selection'));

const GoogleAuthPopup = ({}) => {
    const { t } = useTranslation(['googleAuthenticator', 'otp']);
    const dispatch = useDispatch();
    const _verificationType = OVT.BIND_AUTHENTICATOR;

    // redux
    const user = useSelector((state) => state.user);
    const popupReducer = useSelector((state) => state.popupReducer);
    const authSettingsReducer = useSelector((state) => state.authSettingsReducer);
    const bindAuthenticatorAuthSettings = authSettingsReducer?.processedData?.[MT?.BIND_AUTHENTICATOR];

    // hooks
    const { googleAuthData } = useGetMemberGoogleAuthData();
    const { fields, setFields, onInputFieldChange } = useGetGoogleAuthFields({ googleAuthKey: googleAuthData?.contact });
    const { isReqPreVerify } = useGetGoogleAuthVerifyStatus();
    const { preferContact, preferMethod, setPreferContact, phoneVerificationMethods } = useGetOtpContactAndMethod({
        module: MT?.BIND_AUTHENTICATOR,
    });

    // state
    const [isSubmitting, setIsSubmitting] = useState(false);

    // ===== hook useRequestOtp config =====
    const _otpApiParam = {
        contactType: preferContact,
        verificationType: _verificationType,
        verificationMethod: preferMethod || preferContact, // preferContact is fallback only, can don't need de, but just in case =x
        ...(preferContact === OCT?.PHONE && {
            phone: user?.account?.phone,
        }),
        ...(preferContact === OCT?.EMAIL && {
            email: user?.account?.email,
        }),
    };
    const onRequestOtpSuccess = () => {
        setFields((prevFields) => ({
            ...prevFields,
            [GAF.OTP]: { ...prevFields[GAF.OTP], errMsg: '' },
        }));
    };
    const onRequestOtpFail = (err) => {
        const _errMsg = t(`otp:otp.request.message.${err?.key}`, err?.message, { attemptCount: err?.maxAttempt });

        setFields((prevFields) => ({
            ...prevFields,
            [GAF.OTP]: { ...prevFields[GAF.OTP], errMsg: _errMsg },
        }));
    };
    const { requestOtp, isRequestingOtp, isOtpRequested, otpCountdownTimer, verifyAttemptCount } = useRequestOtp({
        splParams: { otpOptions: { otpType: _verificationType, isPublic: false }, data: _otpApiParam },
        onRequestOtpSuccess, // Callback on successful OTP request
        onRequestOtpFail, // Callback on failed OTP request
    });
    // ===== useRequestOtp config end =====

    // functions
    const closeGAPopup = () => {
        dispatch(popupAction.togglePopUp(POPUP_TYPE.GA, false));
    };

    const onPreferContactChange = (contactType) => {
        setPreferContact(contactType);
    };

    const isOtpButtonDisabled = () => {
        // preferContact === OCT.PHONE && !preferMethod (when contact is phone, need choose verification method first)
        const isDisabled = otpCountdownTimer > 0 || isRequestingOtp || (preferContact === OCT.PHONE && !preferMethod);
        return isDisabled;
    };

    const onSubmitBtnClick = async () => {
        setIsSubmitting(true);

        const apiParam = {
            gaCode: fields?.[GAF.CODE].value,
            otp: fields?.[GAF.OTP].value,
            type: preferContact,
            verificationMethod: preferMethod || preferContact, // preferContact is fallback only, can don't need de, but just in case =x
        };

        try {
            const _submitSuccessCb = popupReducer?.submitSuccessCb;
            await window.SPL_Member.verifyGoogleOTP(apiParam);

            dispatch(authSettingsAction.setGoogleMemberRegistered(true)).then(() => {
                setIsSubmitting(false);
                _submitSuccessCb && _submitSuccessCb();
                dispatch(popupAction.togglePopUp(POPUP_TYPE.GA, false));
            });
        } catch (err) {
            if (err) {
                const { errMsg, errType, key, message } = err;

                // errType is show error at specific fields
                // if no errType, then show API status 400 error message
                if (errType) {
                    setFields((prevFields) => ({
                        ...prevFields,
                        [errType]: { ...prevFields[errType], errMsg: errMsg },
                    }));
                } else {
                    notification.showNotification('error', t(`googleAuthenticator:googleAuthenticator.message.${key}`, message), { autoClose: 2000 });
                }
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    const isSubmitBtnDisabled = () => {
        // check mandatory fields
        if (!fields?.[GAF.OTP]?.value || !fields?.[GAF.CODE]?.value) {
            return true;
        }

        if (!isOtpRequested || isSubmitting) {
            return true;
        } else {
            return false;
        }
    };

    const _authMethods = ou_filterMasterSwitchAuthMethods(bindAuthenticatorAuthSettings?.authMethod);

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Popup isOpen={popupReducer?.items?.[POPUP_TYPE.GA]} className={`google-auth-modal-popup ${styles.googleAuthModalPopup}`}>
                <PopupHeader
                    onRequestClose={closeGAPopup}
                    popupTitle={
                        isReqPreVerify
                            ? t('googleAuthenticator:googleAuthenticator.title.unableBindGa', 'Unable to bind Google Authenticator')
                            : t(`googleAuthenticator:googleAuthenticator.title.bind-and-verify-${preferContact?.toLowerCase()}`, preferContact?.toLowerCase())
                    }
                />

                {isReqPreVerify ? (
                    <ReqPreVerifyBodyContent onRequestClose={closeGAPopup} />
                ) : (
                    <>
                        <NoteContainer />
                        <PopupBody className={`${styles.googleAuthModalBody}`} scssClass={'google-auth-modal-body'}>
                            <div className='standard-form-container google-auth-form-container'>
                                <AuthOtpTabs className={styles.authOtpTabsContainer}>
                                    {(_authMethods || []).map((method) => {
                                        const { type } = method;
                                        const contactType = type && type.toLowerCase();
                                        return (
                                            <Fragment key={method.type}>
                                                <AuthOtpTab
                                                    tabText={t(`otp:otp.tab.${contactType}`)}
                                                    onClick={() => onPreferContactChange(type)}
                                                    isActive={preferContact === type}
                                                />
                                            </Fragment>
                                        );
                                    })}
                                </AuthOtpTabs>

                                {preferContact === OCT.PHONE && (
                                    <PhoneOtpMethodSelection
                                        selectedLabel={preferMethod || t('otp:otp.pleaseSelect', 'Please Select')}
                                        phoneVerificationMethods={phoneVerificationMethods}
                                        preferMethod={preferMethod}
                                    />
                                )}

                                <OtpFormField
                                    layout={'horizontal'}
                                    label={t(`otp:otp.field.${GAF.OTP}.label`, 'Enter the OTP')}
                                    name={GAF.OTP}
                                    value={fields?.[GAF.OTP]?.value}
                                    onChange={onInputFieldChange}
                                    disabled={isOtpButtonDisabled()}
                                    onClick={requestOtp}
                                    buttonText={t('otp:otp.button.getOtp', 'Get OTP') + ` ${otpCountdownTimer > 0 ? otpCountdownTimer : ''}`}
                                    errMsg={fields?.[GAF.OTP]?.errMsg}
                                    verifyAttemptMsg={
                                        isOtpRequested && !fields?.[GAF.OTP]?.errMsg && t('otp:otp.request.message.requestAnotherCode', { attemptCount: verifyAttemptCount })
                                    }
                                />

                                <OTPHelper className={styles.otpHelper} />
                            </div>
                            <DownloadGaApp />
                            <QrContainer verifyKey={googleAuthData?.verifyKey} />
                            {/* <GaForm /> */}
                            <div className='standard-form-container google-auth-form-container'>
                                <FormField
                                    label={<Text textKey={'googleAuthenticator:googleAuthenticator.field.key.label'}>{'Key'}</Text>}
                                    type={'text'}
                                    name={GAF.KEY}
                                    value={fields?.[GAF.KEY]?.value}
                                    errorMessage={fields?.[GAF.KEY]?.errMsg}
                                    readOnly={true}
                                    onCopyClick={() => core.copy(t, googleAuthData?.contact || null)}
                                    layout='horizontal'
                                />

                                <FormField
                                    label={<Text textKey={'googleAuthenticator:googleAuthenticator.field.gaCode.label'}>{'Authenticator Code'}</Text>}
                                    type={'text'}
                                    onChange={onInputFieldChange}
                                    name={GAF.CODE}
                                    value={fields?.[GAF.CODE]?.value}
                                    errorMessage={fields?.[GAF.CODE]?.errMsg}
                                    layout='horizontal'
                                    isMandatory={true}
                                />

                                <SubmitButton
                                    onClick={onSubmitBtnClick}
                                    disabled={isSubmitBtnDisabled()}
                                    text={t('googleAuthenticator:googleAuthenticator.button.bind', 'Bind')}
                                />
                            </div>
                        </PopupBody>
                    </>
                )}
            </Popup>
        </Suspense>
    );
};

export default GoogleAuthPopup;

export const ReqPreVerifyBodyContent = ({ onRequestClose }) => {
    const { t } = useTranslation('googleAuthenticator'); //TODO: convert all to use specific page (e.g. bank-detail.json)
    return (
        <PopupBody className={`${styles.googleAuthModalFailBody}`} scssClass={'google-auth-modal-fail-body'}>
            <div className={`${styles.redCrossIcon} red-cross-icon`}>
                <SVG src={'/public/html/images/common_img/icon-important-notice.svg'} />
            </div>
            <div className='failed-message'>
                {t(
                    'googleAuthenticator:googleAuthenticator.message.bindFailMessage',
                    'Unable to bind Google Authenticator. Kindly verify your email and/or phone number to proceed'
                )}
            </div>

            <div className={`${styles.dismissBtn} standard--button-container`}>
                <button className='standard-button' onClick={onRequestClose}>
                    {t('googleAuthenticator:googleAuthenticator.button.dismiss', 'Dismiss')}
                </button>
            </div>
        </PopupBody>
    );
};

// UI components
export const NoteContainer = () => {
    const { t } = useTranslation('googleAuthenticator');
    return (
        <div className={`${styles.noteContainer} note-container`}>
            <div className={`${styles.noteContent} note-content`}>
                <div className='note-icon'>
                    <SVG src='/public/html/images/common_img/icon-guideline.svg' />
                </div>

                <span>
                    {' '}
                    <Text textKey={'googleAuthenticator:googleAuthenticator.info.addCryptoReqAddGA'}>
                        {t('To add Crypto Address, you will need to add Google Authenticator to your account')}
                    </Text>
                </span>
            </div>
        </div>
    );
};

export const DownloadGaApp = () => {
    const { t } = useTranslation('googleAuthenticator');
    const { commonHrefRedirect } = useCommonHrefRedirect();

    return (
        <div className={`${styles.appDlContainer} app-dl-container`}>
            <div className={`${styles.appIcon}`}>
                <SVG src={'/public/html/images/google-auth/authenticate-app-icon.svg'} />
            </div>
            <div className={`${styles.dlContainer}`}>
                <span>
                    {' '}
                    <Text textKey={'googleAuthenticator:googleAuthenticator.info.dlGaMsg'}>{t('Do not have Google Authenticator? Download from google')}</Text>
                </span>
                <div className={`${styles.buttonContainer} standard-button-container`}>
                    <Button
                        className={`${styles.iosButton} standard-button`}
                        onClick={() => commonHrefRedirect('https://apps.apple.com/my/app/google-authenticator/id388497605')}
                    >
                        <div className={styles.iosIcon}>
                            <SVG src={'/public/html/images/google-auth/icon-ios.svg'} />
                        </div>
                        <span>
                            <Text textKey={'googleAuthenticator:googleAuthenticator.os.ios'}>{'Apple IOS'}</Text>
                        </span>
                    </Button>
                    <Button
                        className={`${styles.androidButton} standard-button`}
                        onClick={() => commonHrefRedirect('https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2')}
                    >
                        <div className={styles.androidIcon}>
                            <SVG src={'/public/html/images/google-auth/icon-android.svg'} />
                        </div>
                        <span>
                            <Text textKey={'googleAuthenticator:googleAuthenticator.os.android'}>{'Android'}</Text>
                        </span>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export const QrContainer = ({ verifyKey }) => {
    const { t } = useTranslation('googleAuthenticator');
    return (
        <div className={styles.qrContainer}>
            <LazyLoadImage className='qr' src={verifyKey} alt='ga-qr' />
            <span>
                {t('* ')}
                <Text textKey={'googleAuthenticator:googleAuthenticator.info.scanQrOrInputKeyManually'}>
                    {t('You may scan the QR code or input key manually at the bottom')}
                </Text>
            </span>
        </div>
    );
};

export const SubmitButton = ({ onClick, text, disabled }) => {
    return (
        <div className={`standard-button-container ${styles.submitBtnContainer}`}>
            <Button className={`standard-button standard-submit-button ${styles.submitBtn}`} onClick={onClick} disabled={disabled}>
                <span>{text}</span>
            </Button>
        </div>
    );
};
