import React, { lazy, Suspense } from 'react';
// import * as serviceWorker from './serviceWorker';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import App from './layout/App/App';
import store from './redux/store/store';
import Modal from 'react-modal';

// import 'bootstrap/dist/css/bootstrap.min.css';
import(`./assets/${window.styleGroup}/base.scss`).then(() => {
    import(`./layout/App/${window.layoutGroup}/Layout`).then((data) => {
        const FooterTop = lazy(() => import(`root/components/FooterTop`));
        const FooterBottom = lazy(() => import(`root/components/FooterBottom`));

        let AppDynamic = data.default;
        let app = (
            <Provider store={store}>
                <BrowserRouter>
                    <App component={AppDynamic} />
                </BrowserRouter>
            </Provider>
        );
        let footerTop = (
            <Provider store={store}>
                <BrowserRouter>
                    <Suspense fallback={<div></div>}>
                        <div className='footer-section'>
                            <FooterTop />
                        </div>
                    </Suspense>
                </BrowserRouter>
            </Provider>
        );
        let footerBtm = (
            <Provider store={store}>
                <BrowserRouter>
                    <Suspense fallback={<div></div>}>
                        <div className='footer-section'>
                            <FooterBottom />
                        </div>
                    </Suspense>
                </BrowserRouter>
            </Provider>
        );
        let interval;
        interval = setInterval(() => {
            // check whether finger print is ready
            if (window.SPL_FingerPrint) {
                window.SPL_FingerPrint.initialize();
                clearInterval(interval);
            }
        }, 500);
        window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

        let initAppInterval = setInterval(() => {
            // wait appjs load together
            if (window.initApp) {
                if (!window.location.pathname.includes('/game')) {
                    // hide when using game page
                    ReactDOM.render(footerTop, document.getElementById('footer-top'));
                    ReactDOM.render(footerBtm, document.getElementById('footer-btm'));
                }

                clearInterval(initAppInterval);
            }
        }, 100);

        ReactDOM.render(app, document.getElementById('root'));
        Modal.setAppElement('#root');
    });
});
// serviceWorker.register();
