import { screenConstants } from '../constant';

const initialState = {
  width: window.innerWidth,
  height: window.innerHeight,
  isMobile: window.innerWidth <= 768 ? true : false,
  viewType: window.innerWidth <= 768 ? "mobile" : "web",
  isMobileAgent: false
};

export function screen(state = initialState, action) {
  switch (action.type) {
    case screenConstants.UPDATE_SCREEN_SIZE:
      return {
        ...state,
        width: action.payload.width,
        height: action.payload.height,
        isMobile: action.payload.isMobile,
        viewType: action.payload.viewType,
        isMobileAgent: action.payload.isMobileAgent 
      };

    default:
      return state;
  }
}
