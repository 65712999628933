import { passwordConstants } from '../constant';

const initialState = {
    status: false,
    rawData: {
        passwordConfigApiData: null,
        status: false,
    },
    processedData: {
        passwordConfig: null,
    },
};

export function password(state = initialState, action) {
    switch (action.type) {
        case passwordConstants.PASSWORD_CONFIG:
            return {
                ...state,
                processedData: {
                    ...state.processedData,
                    passwordConfig: action.payload.passwordConfig,
                },
            };
        case passwordConstants.PASSWORD_CONFIG_API_DATA:
            return {
                ...state,
                rawData: {
                    ...state.rawData,
                    passwordConfigApiData: action.payload.passwordConfigApiData,
                    status: true,
                },
            };
        default:
            return state;
    }
}
