import { dropdownConstants } from '../constant';
import store from '../store/store';

export const dropdownAction = {
    dropdownUpdate,
};

function dropdownUpdate(dropdownObj) {
    return (dispatch) => {
        const certainElementDropdownStore = store.getState()?.certainElementDropdown;

        if (!certainElementDropdownStore && !dropdownObj) {
            return;
        }

        if (!dropdownObj) {
            dropdownObj = {
                certainElementDropdown: null,
            };
        }

        dispatch({ type: dropdownConstants.DROPDOWN_UPDATE, payload: dropdownObj });
    };
}
