import { modalConstants } from '../constant';

const initialState = {
  loginModalVisible: false
};

export function modal(state = initialState, action) {
  switch (action.type) {
    case modalConstants.UPDATE_LOGIN_MODAL:
      return {
        ...state,
        loginModalVisible: action.payload,
      };
    
    default:
      return state;
  }
}
