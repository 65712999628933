import { layoutConstants } from '../constant';
export const layoutAction = {
  setLayout
};

function setLayout(designs) {
  return dispatch => {
    dispatch({ type: layoutConstants.SET_LAYOUT, payload: designs });
  };
}
