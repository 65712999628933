import { PhoneNumberUtil } from 'google-libphonenumber';

export function pu_getTelCodeFromPhoneNumber(phoneNumber) {
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
        phoneUtil.parse(phoneNumber); // pre validate phone, sometime the format is invalid set from BE (e.g. auto generated)
        const number = phoneUtil.parseAndKeepRawInput(phoneNumber);
        const isValid = phoneUtil.isValidNumber(number);

        if (isValid) {
            const telCode = number.getCountryCode();
            return telCode;
        } else {
            return null;
        }
    } catch (error) {
        console.error(error);
        return null;
    }
}
