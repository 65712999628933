import { walletConstant } from '../constant';

const initialState = {
  loadWallet: false,
};

export function wallet(state = initialState, action) {
  switch (action.type) {
    case walletConstant.UDPATE_LOAD_WALLET:
      return {
        ...state,
        loadWallet: action.payload.loadWallet,
        
      };

    default:
      return state;
  }
}
