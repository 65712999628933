import React from 'react';
const Loading = (props) => {
  // useEffect(() => {
  //   const logo = document.querySelectorAll('#logo path');

  //   for (let i = 0; i < logo.length; i++) {
  //     console.log(`Letter ${i} is ${logo[i].getTotalLength()}`);
  //   }
  // }, []);
  return (
    <div className='loading-container'>
      <div className='loading'>
        <svg id='logo' width='595' height='108' viewBox='0 0 595 108' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M59.3438 59.7891H16.3828V105H2.88281V2.625H66.3047V13.7344H16.3828V48.75H59.3438V59.7891Z' stroke='#e40e0d' strokeWidth='5' />
          <path
            d='M127.055 78.2812H84.1641L74.5312 105H60.6094L99.7031 2.625H111.516L150.68 105H136.828L127.055 78.2812ZM88.2422 67.1719H123.047L105.609 19.2891L88.2422 67.1719Z'
            stroke='#e40e0d'
            strokeWidth='5'
          />
          <path
            d='M164.461 105V2.625H193.359C202.266 2.625 210.141 4.59375 216.984 8.53125C223.828 12.4688 229.102 18.0703 232.805 25.3359C236.555 32.6016 238.453 40.9453 238.5 50.3672V56.9062C238.5 66.5625 236.625 75.0234 232.875 82.2891C229.172 89.5547 223.852 95.1328 216.914 99.0234C210.023 102.914 201.984 104.906 192.797 105H164.461ZM177.961 13.7344V93.9609H192.164C202.57 93.9609 210.656 90.7266 216.422 84.2578C222.234 77.7891 225.141 68.5781 225.141 56.625V50.6484C225.141 39.0234 222.398 30 216.914 23.5781C211.477 17.1094 203.742 13.8281 193.711 13.7344H177.961Z'
            stroke='#e40e0d'
            strokeWidth='5'
          />
          <path
            d='M313.945 78.2812H271.055L261.422 105H247.5L286.594 2.625H298.406L337.57 105H323.719L313.945 78.2812ZM275.133 67.1719H309.938L292.5 19.2891L275.133 67.1719Z'
            stroke='#e40e0d'
            strokeWidth='5'
          />
          <path
            d='M368.508 72.7266L370.477 86.2266L373.359 74.0625L393.609 2.625H405L424.758 74.0625L427.57 86.4375L429.75 72.6562L445.641 2.625H459.211L434.391 105H422.086L400.992 30.3984L399.375 22.5938L397.758 30.3984L375.891 105H363.586L338.836 2.625H352.336L368.508 72.7266Z'
            stroke='#ffd900'
            strokeWidth='5'
          />
          <path d='M488.742 105H475.242V2.625H488.742V105Z' stroke='#ffd900' strokeWidth='5' />
          <path
            d='M592.172 105H578.602L527.062 26.1094V105H513.492V2.625H527.062L578.742 81.8672V2.625H592.172V105Z'
            stroke='#ffd900'
            strokeWidth='5'
          />
        </svg>
      </div>
    </div>
  );
};

export default Loading;
