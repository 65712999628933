import { regionConstants } from '../constant/';

export const regionAction = {
    fetchCountryJson,
    fetchProcessedRegions,
};

function fetchCountryJson() {
    return (dispatch) => {
        window.SPL_JsonSettings.getCountryJson(function (countryJson) {
            dispatch({ type: regionConstants.FETCH_COUNTRY_JSON, payload: { countryJson } });
        });
    };
}

/**
 * get processed regions
 * NOTE: required fetchCountryJson first
 */
function fetchProcessedRegions() {
    return (dispatch, getState) => {
        const { regionReducer } = getState();

        const countryRawJson = regionReducer?.rawData?.countryJson;

        const finalRegions = [];
        (Object.keys(countryRawJson) || []).forEach((telCode) => {
            const regionData = countryRawJson?.[telCode];
            const updatedRegionData = {
                ...regionData,
                telCode: `+${telCode}`,
            };
            finalRegions.push(updatedRegionData);
        });

        dispatch({ type: regionConstants.FETCH_PROCESSED_REGIONS, payload: { regions: finalRegions } });
    };
}
