import { registerConstant } from '../constant';

const initialState = {
    telCode: '',
    telCodeCountry: '',
};

export function register(state = initialState, action) {
    switch (action.type) {
        case registerConstant.SELECTED_TELCODE:
            return {
                ...state,
                telCode: action.payload.telCode,
                telCodeCountry: action.payload.telCodeCountry,
            };
        default:
            return state;
    }
}
