import { constSystemFeature } from '../constant';
export const systemFeatureAction = {
    loadSystemFeature,
};

function loadSystemFeature() {
    return async (dispatch, getState) => {
        const { language } = getState();

        const response = await window.SPL_Content.getSystemFeature(language?.currencyLang);
        dispatch({ type: constSystemFeature.LOAD_SYSTEM_FEATURE, payload: response });
    };
}
