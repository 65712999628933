import React from 'react';
import styles from './button.module.scss';

const Button = ({ className = '', style = {}, variant, disabled, onClick, children }) => {
    return (
        <button className={`${styles.button || ''} ${styles[variant] || ''} ${className || ''} atoms-button`} style={style} disabled={disabled} onClick={onClick}>
            {children}
        </button>
    );
};

export default Button;
