import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// components

// logic/helper/
import { ou_getPhoneMethods, ou_filterMasterSwitchAuthMethods } from '@utils/otp-util';

export const useRequestOtp = ({
    splParams,
    arrayParams,
    onRequestOtpSuccess, // Callback on successful OTP request
    onRequestOtpFail, // Callback on failed OTP request
    isOldFlow,
}) => {
    // old flow is pass arrayParams. if got arrayParams

    const [isRequestingOtp, setIsRequestingOtp] = useState(false);
    const [isOtpRequested, setIsOtpRequested] = useState(false);
    const [otpCountdownTimer, setOtpCountdownTimer] = useState(null);
    const [verifyAttemptCount, setVerifyAttemptCount] = useState(null);

    useEffect(() => {
        let timer;
        if (otpCountdownTimer > 0) {
            timer = setInterval(() => {
                setOtpCountdownTimer((prevDuration) => {
                    if (prevDuration > 0) {
                        return prevDuration - 1;
                    } else {
                        clearInterval(timer); // Clear the interval when countdown reaches 0
                        return 0;
                    }
                });
            }, 1000); // Update the timer every 1 second (1000 milliseconds)
        }

        return () => clearInterval(timer); // Cleanup the interval on component unmount
    }, [otpCountdownTimer]);

    const requestOtp = async () => {
        setIsRequestingOtp(true);
        try {
            const res = isOldFlow ? await window.SPL_Member.triggerSendVerification(arrayParams) : await window.SPL_Member.postRequestOTP(splParams);
            setVerifyAttemptCount(res?.data?.verifyAttempt);
            onRequestOtpSuccess(); // Delegate success handling
        } catch (err) {
            let _errObj = {
                key: err?.response?.data?.errorCode,
                message: err?.response?.data?.message,
                description: err?.response?.data?.description,
            };

            // temp handling until BE introduce errorCode
            if (_errObj?.description === 'Maximum Attempts Exceed') {
                _errObj.key = isOldFlow ? 'maximumAttempt' : 'maximumAttemptExceedWithLimit';
                _errObj.maxAttempt = _errObj.message;
            } else if (_errObj?.message === 'phoneNotExist') {
                _errObj.key = 'phoneNotExist';
            } else if (_errObj?.message === 'emailNotExist') {
                _errObj.key = 'emailNotExist';
            } else if (_errObj?.description === 'Invalid Key') {
                _errObj.key = 'invalidOTP';
            }

            onRequestOtpFail(_errObj); // Delegate error handling
        } finally {
            setOtpCountdownTimer(60);
            setIsOtpRequested(true);
            setIsRequestingOtp(false);
        }
    };

    return {
        requestOtp,
        isRequestingOtp,
        isOtpRequested,
        otpCountdownTimer,
        verifyAttemptCount,
        setIsOtpRequested,
    };
};

export const useGetOtpContactAndMethod = ({ module }) => {
    const { t } = useTranslation('otp'); //TODO: convert all to use specific page (e.g. bank-detail.json)

    // redux
    const authSettingsReducer = useSelector((state) => state.authSettingsReducer);
    const _masterSwitchAuthSettingsModuleData = authSettingsReducer?.processedData?.[module];
    const portal = useSelector((state) => state.portal);

    // state
    const [preferContact, setPreferContact] = useState(null);
    const [preferMethod, setPreferMethod] = useState(null);
    const [phoneVerificationMethods, setPhoneVerificationMethods] = useState(null);

    useEffect(() => {
        const _phoneOtpMethods = _masterSwitchAuthSettingsModuleData
            ? ou_filterMasterSwitchAuthMethods(_masterSwitchAuthSettingsModuleData?.authMethod, 'PHONE')
            : ou_getPhoneMethods(portal);
        const _processedPhoneOtpMethods = (_phoneOtpMethods || []).map((method) => {
            return {
                ...method,
                onClick: () => {
                    setPreferMethod(method?.type);
                },
            };
        });
        setPhoneVerificationMethods(_processedPhoneOtpMethods || []);
    }, []);

    useEffect(() => {
        const initDefaultAuthMethod = () => {
            if (_masterSwitchAuthSettingsModuleData?.authMethod?.length > 0) {
                const defaultOtpContact = ou_filterMasterSwitchAuthMethods(_masterSwitchAuthSettingsModuleData?.authMethod)?.[0]?.type;
                setPreferContact(defaultOtpContact);
            }
        };
        initDefaultAuthMethod();
    }, [_masterSwitchAuthSettingsModuleData]);

    const handleSetPreferContact = (contact) => {
        setPreferContact(contact);
        setPreferMethod(null);
    };

    return {
        preferContact,
        preferMethod,
        setPreferContact,
        setPreferMethod,
        handleSetPreferContact,
        phoneVerificationMethods,
    };
};
