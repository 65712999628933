import { regionConstants } from '../constant';

const initialState = {
    rawData: {
        countryJson: null, // process get regions
    },
    processedData: {
        regions: null,
    },
};

export function regionReducer(state = initialState, action) {
    switch (action.type) {
        case regionConstants.FETCH_COUNTRY_JSON:
            return {
                ...state,
                rawData: {
                    ...state.rawData,
                    countryJson: action.payload.countryJson,
                },
            };
        case regionConstants.FETCH_PROCESSED_REGIONS:
            return {
                ...state,
                processedData: {
                    ...state.rawData,
                    regions: action.payload.regions,
                },
            };
        default:
            return state;
    }
}
