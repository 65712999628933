import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { moment } from 'moment';
const backend = new Backend();
backend.init(null, {
    loadPath: '/locales/{{lng}}/{{ns}}.json',
    queryStringParams: { timeStamp: window.time },
    customHeaders: { Vary: 'Accept-Encoding' },

    // path to post missing resources
    addPath: 'locales/add/{{lng}}/{{ns}}',
});

i18n.use(backend)
    // detect user language
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    .init({
        defaultNS: 'global',
        ns: ['settings', 'register'],
        whitelist: ['en', 'th', 'ms', 'id', 'vi', 'zh-CN', 'zh-TW'],
        fallbackLng: 'en',
        debug: false,
        interpolation: {
            format: function (value, format, lng) {
                if (format === ',') return value.replace(',', '.');
                if (value instanceof Date) return moment(value).format(format);
                return value;
            },
            escapeValue: false,
        },
        react: {
            bindI18n: 'languageChanged',
            bindI18nStore: '',
            transEmptyNodeValue: '',
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'span'],
            useSuspense: false,
            wait: true,
        },
    });

export default i18n;
