export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    GET_MEMBER_REQUEST: 'GET_MEMBER_REQUEST',
    GET_MEMBER_ACCOUNT: 'GET_MEMBER_ACCOUNT',
    GET_MEMBER_ACCOUNT_FAILED: 'GET_MEMBER_ACCOUNT_FAILED',
    SHOW_RESET_PASSWORD: 'SHOW_RESET_PASSWORD',
    LOGOUT: 'USERS_LOGOUT',
    IS_LOADING: 'IS_LOADING',
    HIDE_HOMEPAGE_ANNOUNCEMENT: 'HIDE_HOMEPAGE_ANNOUNCEMENT',
    HIDE_DEPOSIT_ANNOUNCEMENT: 'HIDE_DEPOSIT_ANNOUNCEMENT',
    HIDE_WITHDRAW_ANNOUNCEMENT: 'HIDE_WITHDRAW_ANNOUNCEMENT',
    GET_UNREAD_MSG: 'GET_UNREAD_MSG',
    UPDATE_USER_VERIFICATION: 'UPDATE_USER_VERIFICATION',
};
